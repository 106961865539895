import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import Share from "../components/Share/Share"
import Tags from "../components/Tags/Tags"
import Comments from "../components/Comments/Comments"
import PostRenderer from "../components/PostRenderer/PostRenderer"
import NewsletterBox from "../components/NewsletterBox/NewsletterBox"

import styles from "./post.module.css"
import { MDXRenderer } from "gatsby-plugin-mdx"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const twitter = data.site.siteMetadata.social.twitter
  const tags = post.frontmatter.tags
  const slug = post.frontmatter.slug

  return (
    <Layout location={location} title={siteTitle} headerAttrs={{ post }}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.excerpt}
        keywords={tags.join(",")}
      />

      <div className={styles.post}>
        <MDXRenderer>{post.body}</MDXRenderer>
      </div>

      <Tags tags={tags} />

      <Share
        socialConfig={{
          twitter,
          config: {
            url: `${siteUrl}${slug}`,
            title: post.frontmatter.title,
          },
        }}
        tags={tags}
      />

      <NewsletterBox />

      {/* <Comments /> */}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
        social {
          twitter
        }
      }
    }
    mdx(frontmatter: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        slug
        title
        date
        tags
        draft
      }
      excerpt
    }
  }
`
