import React from "react"
import styles from "./NewsletterBox.module.css"
import useScript from "../../hooks/useScript"

const NewsletterBox = () => {
  // eslint-disable-next-line no-unused-vars
  const [ckLoaded, ckError] = useScript(
    "https://fierce-creator-863.ck.page/70a1231be4/index.js",
    "70a1231be4"
  )

  return (
    <div>
      {ckLoaded && (
        <div className={styles.newsletterBox}>
          <h3>New themes and new blog posts directly in your inbox.</h3>
          <a
            className="btn"
            data-formkit-toggle="70a1231be4"
            href="https://fierce-creator-863.ck.page/70a1231be4"
          >
            Subscribe to the newsletter
          </a>
          <p className={styles.small}>Not more than 1 email per month.</p>
        </div>
      )}
    </div>
  )
}

export default NewsletterBox
