import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	RedditShareButton,
} from 'react-share'

import styles from './share.module.css'

const Share = ({ socialConfig, tags }) => (
  <div className={styles.socialContainer}>
    <h4 className={styles.shareTitle}>Share on</h4>
    <div className={styles.socials}>
      <FacebookShareButton url={socialConfig.config.url} className={styles.facebook}>
        <FontAwesomeIcon icon={['fab', 'facebook-f']} /> Facebook
      </FacebookShareButton>

      <TwitterShareButton url={socialConfig.config.url} className={styles.twitter} title={socialConfig.config.title} via={socialConfig.twitter.split('@').join('')} hashtags={tags} >
        <FontAwesomeIcon icon={['fab', 'twitter']} /> Twitter
      </TwitterShareButton>

      <LinkedinShareButton url={socialConfig.config.url} className={styles.linkedin} title={socialConfig.config.title} >
        <FontAwesomeIcon icon={['fab', 'linkedin-in']} /> Linkedin
    </LinkedinShareButton>

      <RedditShareButton url={socialConfig.config.url} className={styles.reddit} title={socialConfig.config.title} >
        <FontAwesomeIcon icon={['fab', 'reddit-alien']} /> Reddit
      </RedditShareButton>

      <WhatsappShareButton url={socialConfig.config.url} className={styles.whatsapp} title={socialConfig.config.title} >
        <FontAwesomeIcon icon={['fab', 'whatsapp']} /> Whatsapp
      </WhatsappShareButton>
    </div>
  </div>
)

Share.propTypes = {
	socialConfig: PropTypes.shape({
		twitter: PropTypes.string.isRequired,
		config: PropTypes.shape({
			url: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		}),
	}).isRequired,
	tags: PropTypes.arrayOf(PropTypes.string),
}

Share.defaultProps = {
	tags: [],
}

export default Share
