import React, { useEffect, Fragment } from "react"
import Prism from "prismjs"

const notionLanguageToPrism = {
  "Plain Text": "plaintext",
  JavaScript: "javascript",
  Bash: "bash",
  JSON: "json",
  Elixir: "elixir",
  HTML: "html",
  CSS: "css",
  SCSS: "scss",
  Markup: "ftl",
}

function renderBlockImage(meta) {
  return (
    <img
      key={Math.random()}
      src={meta.publicImageUrl}
      style={{ width: `${meta.width}px` }}
      alt=""
    />
  )
}

function renderCodeBlock(children, meta) {
  const lang = notionLanguageToPrism[meta.language] || "plaintext"
  return (
    <pre key={Math.random()} className={`language-${lang} line-numbers`}>
      <code>{children}</code>
    </pre>
  )
}

function renderBlockText(children) {
  return <p key={Math.random()}>{children}</p>
}

function renderBlockHeader(children, level) {
  switch (level) {
    case 1:
      return <h2 key={Math.random()}>{children}</h2>
    case 2:
      return <h3 key={Math.random()}>{children}</h3>
    case 3:
    default:
      return <h4 key={Math.random()}>{children}</h4>
  }
}

function renderBulletedList(children) {
  return <ul key={Math.random()}>{children}</ul>
}

function renderNumberedList(children) {
  return <ol key={Math.random()}>{children}</ol>
}

function renderListItem(children) {
  return <li key={Math.random()}>{children}</li>
}

function renderDivider() {
  return <hr key={Math.random()} />
}

function renderQuote(children) {
  return (
    <blockquote key={Math.random()} className="quote">
      {children}
    </blockquote>
  )
}

function renderPage(children) {
  return <div>{children}</div>
}

function renderBlock(type, meta, children) {
  switch (type) {
    case "page":
      return renderPage(children)

    case "text":
      return renderBlockText(children)

    case "code":
      return renderCodeBlock(children, meta)

    case "image":
      return renderBlockImage(meta)

    case "header":
      return renderBlockHeader(children, 1)

    case "sub_header":
      return renderBlockHeader(children, 2)

    case "sub_sub_header":
      return renderBlockHeader(children, 3)

    case "bulleted_list":
      return renderBulletedList(children)

    case "numbered_list":
      return renderNumberedList(children)

    case "numbered_list__item":
      return renderListItem(children)

    case "bulleted_list__item":
      return renderListItem(children)

    case "divider":
      return renderDivider()

    case "quote":
      return renderQuote(children)

    case "callout":
      return null

    case "meta":
      // we don't parse this block - it contains the pahe meta information such as the slug
      return null

    default:
      console.log("@@@ unknow type to render>renderBlock>", type)
      return null
  }
}

const _attToClassName = {
  i: "italic",
  b: "bold",
  s: "stroke",
  c: "code",
}

function mkRenderFuncs(_notionPageBlog) {
  return {
    wrapText: text => {
      return <Fragment key={Math.random()}>{text}</Fragment>
    },
    renderTextAtt: (children, att) => {
      const className = _attToClassName[att]
      if (!className) {
        console.log(`@@@ no text attribute for: ${att}`)
      }
      return (
        <span key={Math.random()} className={className || ""}>
          {children}
        </span>
      )
    },
    renderLink: (children, ref) => {
      return (
        <a key={Math.random()} href={ref}>
          {children}
        </a>
      )
    },
    renderBlock: (type, meta, children) => {
      return renderBlock(type, meta, children)
    },
  }
}

const PostRenderer = ({ data, renderer, debug }) => {
  useEffect(() => {
    Prism.highlightAll()
  }, [])
  const { notionPageBlog } = data
  const renderFuncs = mkRenderFuncs(notionPageBlog)
  const child = renderer.render(renderFuncs)

  return (
    <div>
      <div>{child}</div>
      {debug && (
        <div>
          <h2>Debugging PostRenderer:</h2>
          <pre>{JSON.stringify(notionPageBlog, null, "  ")}</pre>
        </div>
      )}
    </div>
  )
}

export default PostRenderer
