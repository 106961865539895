import React from "react"
import PropTypes from 'prop-types'

import Tag from '../Tag/Tag'

import styles from './tags.module.css'

const Tags = ({ tags }) => (
    <div className={styles.tags}>
    <h4 className={styles.tagsTitle}>Tags</h4>
    <div className={styles.tagsList}>
    {tags.map(t => <Tag key={t} tag={t} />)}
  </div>
    </div>
);

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
}

Tags.defaultProps = {
	tags: [],
};

export default Tags
